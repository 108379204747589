
import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import { Link } from "gatsby";
import { render } from 'react-dom';
import {Helmet} from "react-helmet";

export default () => (
    <Layout>
  <Helmet>
      <meta charSet="utf-8" />
      <title> Danke </title>
      
  </Helmet>
  <section className="pt-20 md:pt-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-blue-500 leading-none">
          Danke dir für dein Interesse! Bald holen wir auch bei dir alles ab!
          </h1>
  </div>
  
  </div>
  </section>
 
  </Layout>
  );